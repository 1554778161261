import {Pipe, PipeTransform} from '@angular/core';


export class BarRatingPipe implements PipeTransform {

  transform(value: number = 0, titles?: any): any {
    /** Initialize value with 0 in case of undefined */
    return titles[value] || value + 1;
  }

static decorators: DecoratorInvocation[] = [
{ type: Pipe, args: [{
  name: 'rateTitle'
}, ] },
];
/** @nocollapse */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
