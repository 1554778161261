import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Angular2CsvComponent } from './angular2-csv.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [Angular2CsvComponent],
  exports: [Angular2CsvComponent]
})
export class Angular2CsvModule { }
