import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'angular2csv',
  template: `<button (click)="onDownload()">{{ label_btn }}</button>`,
  styles: []
})

export class Angular2CsvComponent implements OnInit {

  @Input() data: any[];
  @Input() filename: string  = this.filename || 'mycsv.csv';
  @Input() options: Options = this.objectAssign({}, ConfigDefaults, this.options);

  label_btn: string = "download";
  csv: string = '';

  constructor() {}
  /**
   * Event Download
   */
  onDownload(): void {
    this.generateCsv();
  }
  /**
   * ngOnInit
   */
  ngOnInit() {}
  /**
   * [generateCsv description]
   */
  generateCsv(): void {

    if (this.options.useBom) {
      this.csv += CsvConfigConsts.BOM;
    }

    if (this.options.showTitle) {
      this.csv += this.options.title + '\r\n\n';
    }

    this.getHeaders();
    this.getBody();

    if (this.csv === '') {
      console.log('Invalid data');
      return;
    }

    let blob = new Blob([this.csv], {type: 'text/csv;charset=utf8;'});

    if (navigator.msSaveBlob) {

      let filename = this.options.filename.replace(/ /g, '_') + '.csv';
      navigator.msSaveBlob(blob, filename);

    } else {

      let uri = 'data:attachment/csv;charset=utf-8,' + encodeURI(this.csv);
      let link = document.createElement('a');

      link.href = URL.createObjectURL(blob);

      link.setAttribute('visibility', 'hidden');
      link.download = this.filename.replace(/ /g, '_') + '.csv';

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    }

    this.csv = '';
  }
  /**
   * Create Headers for Csv File
   */
  getHeaders(): void {
    if (this.options.headers.length > 0) {
      let row = '';
      for (let column of this.options.headers) {
        row += column + this.options.fieldSeparator;
      }

      row = row.slice(0, -1);
      this.csv += row + CsvConfigConsts.EOL;
    }
  }
  /**
   * Create Headers
   */
  getBody(): void {

    for (let dataRow of this.data) {

      let row = '';

      if(this.isEmptyObject(dataRow) && this.options.removeNewLines) {
        continue;
      }

      if (typeof this.options.keys !== 'undefined' && this.options.keys.length) {

        for (let key of this.options.keys) {
          row += this.formartData(dataRow[key]) + this.options.fieldSeparator;
        }

        row = row.slice(0, -1);
        this.csv += row + CsvConfigConsts.EOL;

      } else {

        for (let key in dataRow) {
          if(dataRow[key]) {
            row += this.formartData(dataRow[key]) + this.options.fieldSeparator;
          }
        }
        this.csv += row + CsvConfigConsts.EOL;

      }
    }
  }
  /**
   * Format Data
   */
  formartData(data: any) {

    if (this.options.decimalseparator === 'locale' && this.isFloat(data)) {
      return data.toLocaleString();
    }

    if (this.options.decimalseparator !== '.' && this.isFloat(data)) {
      return data.toString().replace('.', this.options.decimalseparator);
    }

    if (typeof data === 'string') {
      data = data.replace(/"/g, '""');

      if (this.options.quoteStrings || data.indexOf(',') > -1 || data.indexOf("\n") > -1 || data.indexOf("\r") > -1) {
        data = this.options.quoteStrings + data + this.options.quoteStrings;
      }

      return data;
    }

    if (typeof data === 'boolean') {
      return data ? 'TRUE' : 'FALSE';
    }

    return data;
  }
  /**
   * Validate if object is not empty
   */
  isEmptyObject(obj: any) {
    return (obj && (Object.keys(obj).length === 0));
  }
  /**
   * Get Input is Float
   */
  isFloat(input: any) {
    return +input === input && (!isFinite(input) || Boolean(input % 1));
  }
  /**
   * Add object Values
   */
  toObject(val: any) {
    if (val === null || val === undefined) {
      throw new TypeError('Object.assign cannot be called with null or undefined');
    }
    return Object(val);
  }
  /**
   * Add Values to Object
   */
  objectAssign(target: any, ...source: any[]) {
    let from: any;
    let to = this.toObject(target);
    let symbols: any;

    let hasOwnProperty = Object.prototype.hasOwnProperty;
    let propIsEnumerable = Object.prototype.propertyIsEnumerable;

    for (let s = 1; s < arguments.length; s++) {
      from = Object(arguments[s]);

      for (let key in from) {
        if (hasOwnProperty.call(from, key)) {
          to[key] = from[key];
        }
      }

      if ((<any> Object).getOwnPropertySymbols) {
        symbols = (<any> Object).getOwnPropertySymbols(from);
        for (let symbol of symbols) {
          if (propIsEnumerable.call(from, symbol)) {
            to[symbol] = from[symbol];
          }
        }
      }
    }

    return to;
  }
}
/**
 * Option Interface
 */
export interface Options {
  filename: string;
  fieldSeparator: string;
  quoteStrings: string;
  decimalseparator: string;
  showLabels: boolean;
  showTitle: boolean;
  title: string;
  useBom: boolean;
  headers: string[];
  keys: string[];
  removeNewLines: boolean;
}
/**
 * CsvConfigConsts
 */
export class CsvConfigConsts {

  public static EOL = '\r\n';
  public static BOM = '\ufeff';

  public static DEFAULT_FIELD_SEPARATOR = ',';
  public static DEFAULT_DECIMAL_SEPARATOR = '.';
  public static DEFAULT_QUOTE = '"';
  public static DEFAULT_SHOW_TITLE = false;
  public static DEFAULT_TITLE = 'My Report';
  public static DEFAULT_FILENAME = 'mycsv.csv';
  public static DEFAULT_SHOW_LABELS = false;
  public static DEFAULT_USE_BOM = true;
  public static DEFAULT_HEADER: string[] = [];
  public static DEFAULT_KEY: string[] = [];
  public static DEFAULT_REMOVE_NEW_LINES = false;
}
/**
 * Default Configurations
 */
export const ConfigDefaults: Options = {
  filename: CsvConfigConsts.DEFAULT_FILENAME,
  fieldSeparator: CsvConfigConsts.DEFAULT_FIELD_SEPARATOR,
  quoteStrings: CsvConfigConsts.DEFAULT_QUOTE,
  decimalseparator: CsvConfigConsts.DEFAULT_DECIMAL_SEPARATOR,
  showLabels: CsvConfigConsts.DEFAULT_SHOW_LABELS,
  showTitle: CsvConfigConsts.DEFAULT_SHOW_TITLE,
  title: CsvConfigConsts.DEFAULT_TITLE,
  useBom: CsvConfigConsts.DEFAULT_USE_BOM,
  headers: CsvConfigConsts.DEFAULT_HEADER,
  keys: CsvConfigConsts.DEFAULT_KEY,
  removeNewLines: CsvConfigConsts.DEFAULT_REMOVE_NEW_LINES
};
