import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {BarRatingComponent} from './component/bar-rating.component';
import {BarRatingPipe} from './pipe/bar-rating.pipe';


export class BarRatingModule {
static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
  declarations: [
    BarRatingComponent,
    BarRatingPipe
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    BarRatingComponent
  ]
}, ] },
];
/** @nocollapse */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
